import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: '', // e.g: 'Name | Developer'
  lang: 'fr', // e.g: en, es, fr, jp
  description: '', // e.g: Welcome to my website
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'samantha.jpg',
    title: 'Samantha',
    info: 'Née le 15/06/2021 à Maisons-Alfort',
    info2:
      "Samantha adore se prélasser au soleil, elle se rafraichit une fois par semaine avec de l'eau douce",
    url: '',
    repo: '', // if no repo, the button will not show up
  },
];

// CONTACT DATA
export const contactData = {
  cta: '',
  btn: '',
  email: '',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: 'instagram',
      url: 'https://www.instagram.com/lacabaneauxfleurs.fr/',
    },
  ],
};
